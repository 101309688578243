<template>
  <div>
      <v-card :loading="loading">
        <v-card-title>
          <span v-if="editExistingEvent" class="headline">Kurs bearbeiten</span>
          <span v-else class="headline">Kurs erstellen</span>
        </v-card-title>
        <v-card-text>
          <validation-observer
            ref="eventFormObserver"
            v-slot="{ }"
          >
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="Title"
                  rules="required"
                >
                <v-text-field v-model="form.createEvent.title" label="Kurstitel*" :error-messages="errors" ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="OperatorReference"
                  rules=""
                >
                  <v-text-field v-model="form.createEvent.operatorReference" label="Eigene Referenz / Kursnummer" :error-messages="errors" ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <validation-provider
                  v-slot="{  }"
                  name="Description"
                  rules=""
                >
                  <v-textarea v-model="form.createEvent.description" label="Beschreibung"></v-textarea>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <label>Start- und Enddatum festlegen</label>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-menu
                  ref="menuStartDate"
                  v-model="menu.startDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider
                    v-slot="{errors}"
                    name="StartDate"
                    :rules="{ required: !dateDisabled, regex: /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/ }">
                    <v-text-field
                      v-model="form.createEvent.startDateFormatted"
                      label="Start-Datum"
                      @change="setStartDate"
                      :disabled="dateDisabled"
                      :error-messages="errors"
                      >
                      <template v-slot:append>
                        <v-btn
                          icon
                          class="pb-1"
                          v-bind="attrs"
                          v-on="on">
                          <v-icon>mdi-calendar</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                    </validation-provider>
                  </template>
                  <v-date-picker
                    v-model="form.createEvent.startDate"
                    @input="selectStartDate()"
                  ></v-date-picker>
                </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu
                    ref="menuEndDate"
                    v-model="menu.endDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{errors}"
                        name="StartDate"
                        :rules="{ required: !dateDisabled, regex: /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/ }">
                        <v-text-field
                          v-model="form.createEvent.endDateFormatted"
                          label="End-Datum"
                          @change="setEndDate"
                          :disabled="dateDisabled"
                          :error-messages="errors">
                          <template v-slot:append>
                            <v-btn
                              icon
                              class="pb-1"
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>mdi-calendar</v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="form.createEvent.endDate"
                      @input="selectEndDate()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0">
                <v-checkbox v-model="dateDisabled" class="mt-0" label="Der Kurs hat kein spezifisches Start-/Enddatum"></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <validation-provider
                  v-slot="{ errors  }"
                  name="CourseLevel"
                  rules="required"
                >
                  <v-select label="Kursniveau" :items="courseLevels" item-value="value" item-text="text"
                          multiple v-model="form.createEvent.courseLevel" :error-messages="errors"></v-select>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <v-select label="Sub-Niveau" :items="subLevels" item-value="value" item-text="text"
                          multiple @input="sortSubLevels" v-model="form.createEvent.subLevel"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p>Kategorietags hinzufügen:</p>
                <v-select
                  v-model="form.createEvent.categoryTags"
                  :items="categoryList"
                  attach
                  item-value="id"
                  item-text="name"
                  chips
                  label="Kategorien"
                  multiple
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <validation-provider
                  v-slot="{ errors  }"
                  name="LessonCount"
                  rules=""
                >
                <v-text-field label="Anzahl Lektionen Total" v-model="form.createEvent.lessonCount" :error-messages="errors"></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <validation-provider
                  v-slot="{ errors  }"
                  name="LessonsPerWeek"
                  rules=""
                >
                <v-text-field label="Anzahl Lektionen pro Woche" v-model="form.createEvent.lessonsPerWeek" :error-messages="errors"></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <validation-provider
                  v-slot="{ errors  }"
                  name="TotalCosts"
                  rules="required"
                >
                <v-text-field label="Gesamtkosten CHF" hint="Gesamkosten sind ohne Materialkosten zu verstehen. Materialkosten können optional in der Kursbeschreibung erwähnt werden." v-model="form.createEvent.totalCosts" :error-messages="errors"></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <validation-provider
                  v-slot="{ errors  }"
                  name="ExternalUrl"
                  rules=""
                >
                <v-text-field label="Externe URL" v-model="form.createEvent.externalUrl" hint="In der Kursübersicht kann über diese URL der Kurs direkt aufgerufen werden" :error-messages="errors"></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors  }"
                  name="MultiCourseLink"
                  rules=""
                >
                <v-checkbox class="mt-0" label="Link zu Sammlung von mehreren Kursen" v-model="form.createEvent.multiCourseLink" persistent-hint hint="Anwählen wenn der Kurs als Sammlung von mehreren Kursen zu behandeln ist" :error-messages="errors"></v-checkbox>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="EventLocation"
                  :rules="{ required: true }"
                >
                <v-select
                  v-model="form.createEvent.eventLocation"
                  :items="locationList"
                  item-text="address"
                  item-value="id"
                  :error-messages="errors"
                  label="Kursstandort*">
                </v-select>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="8">
                <p>Kurszeiten eintragen:</p>
                <v-row align="center" v-for="(window, key, index) in eventWindows" :key="window.label">
                  <v-col cols="12" md="3">
                    <v-checkbox
                      v-model="window.enabled"
                      hide-details
                      :label="window.label"
                      class="shrink mr-6 mt-0"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-row>
                        <v-menu
                          ref="timeStartMenu"
                          v-model="window.menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value="window.startTime"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <validation-provider
                              v-slot="{ errors }"
                              name="StartTime"
                              :rules="{ required: true, regex: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/ }"
                            >
                              <v-text-field
                                :disabled="!window.enabled"
                                label="Zeit Start"
                                style="width: 100px"
                                :error-messages="errors"
                                class=""
                                v-model="window.startTime"
                              >
                                <template v-slot:append>
                                  <v-btn
                                    icon
                                    class="pb-1"
                                    v-bind="attrs"
                                    v-on="on">
                                    <v-icon>mdi-clock</v-icon>
                                  </v-btn>
                                </template>
                              </v-text-field>
                            </validation-provider>
                          </template>
                          <v-time-picker
                            format="24hr"
                            v-if="window.menu"
                            v-model="window.startTime"
                            @click:minute="saveStartTime(window.startTime, index)"
                          ></v-time-picker>
                        </v-menu>
                        <v-menu
                          ref="timeEndMenu"
                          v-model="window.menuEnd"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value="window.endTime"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <validation-provider
                              v-slot="{ errors }"
                              name="EndTime"
                              :rules="{ required: true, regex: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/ }"
                            >
                              <v-text-field
                                :disabled="!window.enabled"
                                label="Zeit Ende"
                                style="width: 100px"
                                class="ml-5"
                                :error-messages="errors"
                                v-model="window.endTime"
                              >
                                <template v-slot:append>
                                  <v-btn
                                    icon
                                    class="pb-1"
                                    v-bind="attrs"
                                    v-on="on">
                                    <v-icon>mdi-clock</v-icon>
                                  </v-btn>
                                </template>
                              </v-text-field>
                            </validation-provider>
                          </template>
                          <v-time-picker
                            format="24hr"
                            v-if="window.menuEnd"
                            v-model="window.endTime"
                            @click:minute="saveEndTime(window.endTime, index)"
                          ></v-time-picker>
                        </v-menu>

                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn @click="syncTimes(window.startTime, window.endTime)" icon v-on="on" class="mt-4 ml-3"><v-icon>mdi-autorenew</v-icon></v-btn>
                        </template>
                        <span>Diese Zeit für alle aktiven Tage anwenden.</span>
                      </v-tooltip>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="resetEventDialog; $emit('closeDialog')"
          >
            Abbrechen
          </v-btn>
          <v-btn v-if="editExistingEvent"
                 color="blue darken-1"
                 text
                 :loading="saving"
                 @click="updateEvent()"
          >
            Updaten
          </v-btn>
          <v-btn v-else
            color="blue darken-1"
            text
            :loading="saving"
            @click="saveEvent()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import { showSnack } from '../globalActions'
// eslint-disable-next-line camelcase
import { required, email, regex, required_if, confirmed } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'Dies ist ein Pflichtfeld'
})

extend('email', {
  ...email,
  message: 'Es muss eine Email Adresse eingegeben werden'
})

extend('regex', {
  ...regex,
  message: 'Die Eingabe entspricht nicht dem erwarteten Format'
})

extend('confirmed', {
  ...regex,
  message: 'Das eingegebene Passwort stimmt nicht überein'
})

extend('password', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: 'Das eingegebene Passwort stimmt nicht überein'
})

export default {
  name: 'EventDialog',
  props: ['operatorId', 'editExistingEvent', 'eventItemId'],
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: function () {
    return {
      eventDialogVisible: false,
      eventWindows: {
        mo: {
          enabled: false,
          weekday: 1,
          startTime: '00:00',
          endTime: '00:00',
          label: 'Montag',
          menu: false,
          menuEnd: false
        },
        tu: {
          enabled: false,
          weekday: 2,
          startTime: '00:00',
          endTime: '00:00',
          label: 'Dienstag',
          menu: false,
          menuEnd: false
        },
        we: {
          enabled: false,
          weekday: 3,
          startTime: '00:00',
          endTime: '00:00',
          label: 'Mittwoch',
          menu: false,
          menuEnd: false
        },
        th: {
          enabled: false,
          weekday: 4,
          startTime: '00:00',
          endTime: '00:00',
          label: 'Donnerstag',
          menu: false,
          menuEnd: false
        },
        fr: {
          enabled: false,
          weekday: 5,
          startTime: '00:00',
          endTime: '00:00',
          label: 'Freitag',
          menu: false,
          menuEnd: false
        },
        sa: {
          enabled: false,
          weekday: 6,
          startTime: '00:00',
          endTime: '00:00',
          label: 'Samstag',
          menu: false,
          menuEnd: false
        },
        su: {
          enabled: false,
          weekday: 7,
          startTime: '00:00',
          endTime: '00:00',
          label: 'Sonntag',
          menu: false,
          menuEnd: false
        }
      },
      menu: {
        startDate: false,
        endDate: false
      },
      existingEvent: null,
      dateDisabled: false,
      saving: false,
      loading: false,
      form: {
        createEvent: {
          title: '',
          description: '',
          startDate: '',
          startDateFormatted: '',
          endDateFormatted: '',
          endDate: '',
          multiCourseLink: false,
          hasSubsidyEligibility: false,
          lessonCount: 0,
          courseLevel: [],
          externalUrl: '',
          subLevel: [],
          intensityLevel: '',
          eventLocation: '',
          categoryTags: [],
          totalCosts: 0,
          lessonsPerWeek: 0,
          operatorReference: ''
        }
      },
      courseLevels: [
        { value: 0, text: 'A0' },
        { value: 1, text: 'A1' },
        { value: 2, text: 'A2' },
        { value: 3, text: 'B1' },
        { value: 4, text: 'B2' },
        { value: 5, text: 'C1' },
        { value: 6, text: 'C2' }
      ],
      subLevels: [
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '+' }
      ],
      locationList: [],
      categoryList: []
    }
  },
  mounted () {
    this.getLocations()
    this.getSingleEvent()
    this.getCategoryList()
  },
  methods: {
    getSingleEvent () {
      this.loading = true
      if (this.eventItemId > 0) {
        axios
          .get('/operators/' + this.operatorId + '/events/' + this.eventItemId)
          .then((response) => {
            this.existingEvent = response.data
            this.form.createEvent.title = this.existingEvent.title
            this.form.createEvent.description = this.existingEvent.description
            this.form.createEvent.startDate = this.existingEvent.startDate
            this.form.createEvent.startDateFormatted = new Date(this.existingEvent.startDate).toLocaleDateString()
            this.form.createEvent.endDate = this.existingEvent.endDate
            this.form.createEvent.endDateFormatted = new Date(this.existingEvent.endDate).toLocaleDateString()
            this.form.createEvent.courseLevel = this.existingEvent.courseLevel.split(',').map(Number)
            this.form.createEvent.subLevel = this.existingEvent.subLevel.split(',').map(Number)
            this.form.createEvent.multiCourseLink = this.existingEvent.multiCourseLink
            this.form.createEvent.lessonCount = this.existingEvent.lessonCount
            this.form.createEvent.lessonsPerWeek = this.existingEvent.lessonsPerWeek
            this.form.createEvent.totalCosts = this.existingEvent.totalCosts
            this.form.createEvent.externalUrl = this.existingEvent.externalUrl
            this.form.createEvent.operatorReference = this.existingEvent.operatorReference
            this.form.createEvent.eventLocation = this.existingEvent.EventLocationId

            if (!this.existingEvent.startDate || this.existingEvent.startDate === '0000-00-00') {
              this.dateDisabled = true
              this.form.createEvent.endDateFormatted = null
              this.form.createEvent.startDateFormatted = null
            }

            if (this.existingEvent.EventCategories && this.existingEvent.EventCategories.length > 0) {
              this.existingEvent.EventCategories.forEach(tag => {
                this.form.createEvent.categoryTags.push(tag.id)
              })
            }

            if (this.existingEvent.EventWindows && this.existingEvent.EventWindows.length > 0) {
              this.existingEvent.EventWindows.forEach(window => {
                const dayKey = Object.keys(this.eventWindows).find(key => this.eventWindows[key].weekday === window.weekday)

                this.eventWindows[dayKey].enabled = true
                this.eventWindows[dayKey].startTime = window.timeStart
                this.eventWindows[dayKey].endTime = window.timeEnd
              })
            }
          })
          .finally(() => this.loading = false)
      }
    },
    getLocations () {
      axios
        .get('/operators/' + this.operatorId + '/locations')
        .then((response) => {
          this.locationList = response.data
        })
    },
    getCategoryList () {
      axios
        .get('/operators/' + this.operatorId + '/categories')
        .then((response) => {
          this.categoryList = response.data
        })
    },
    getSingleLocation (locationId) {
      let address = ''
      axios
        .get('/operators/' + this.operatorId + '/locations/' + locationId)
        .then((response) => {
          address = response.data.address
        }).catch((response) => {
          address = response.error
        })

      return address
    },
    syncTimes (startTime, endTime) {
      // sets all eventwindow start and end times to given times, only set enabled windows
      for (const w in this.eventWindows) {
        if (this.eventWindows[w].enabled) {
          this.eventWindows[w].startTime = startTime
          this.eventWindows[w].endTime = endTime
        }
      }
    },
    saveEvent () {
      this.$refs.eventFormObserver.validate()
        .then((valid) => {
          if (valid) {
            this.saving = true
            this.setDates()
            axios.post('/operators/' + this.operatorId + '/events', { eventData: this.form.createEvent, eventWindows: this.eventWindows })
              .then((response) => {
                if (response.status === 200) {
                  showSnack({ message: 'Kurs wurde erstellt', color: 'success' })
                  this.$emit('getEvents')
                  this.$emit('closeDialog')
                } else {
                  showSnack({ message: 'Kurs konnte nicht erstellt werden' })
                }
              })
              .finally(() => this.saving = false)
          } else {
            showSnack({message: 'Das Formular ist nicht korrekt ausgefüllt'})
          }
        })
    },
    updateEvent () {
      this.$refs.eventFormObserver.validate()
        .then((valid) => {
          if (valid) {
            this.saving = true
            this.setDates()
            axios.patch('/operators/' + this.operatorId + '/events/' + this.eventItemId, { eventData: this.form.createEvent, eventWindows: this.eventWindows })
              .then((response) => {
                if (response.status === 200) {
                  showSnack({ message: 'Kurs wurde aktualisiert', color: 'success' })
                  this.$emit('getEvents')
                  this.$emit('closeDialog')
                } else {
                  showSnack({ message: 'Kurs konnte nicht aktualisiert werden' })
                }
              })
              .finally(() => this.saving = false)
          } else {
            showSnack({message: 'Das Formular ist nicht korrekt ausgefüllt'})
          }
        })
    },
    setDates () {
      if (this.dateDisabled) {
        this.form.createEvent.startDate = null
        this.form.createEvent.endDate = null
      }
    },
    resetEventDialog () {
      this.form.createEvent = null
    },
    setStartDate () {
      let dateSplit = this.form.createEvent.startDateFormatted.split('.')
      if (dateSplit.length === 3) {
        this.form.createEvent.startDate = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`
      }
    },
    setEndDate () {
      let dateSplit = this.form.createEvent.endDateFormatted.split('.')
      if (dateSplit.length === 3) {
        this.form.createEvent.endDate = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`
      }
    },
    selectStartDate () {
      this.menu.startDate = false
      this.form.createEvent.startDateFormatted = new Date(this.form.createEvent.startDate).toLocaleDateString()
    },
    selectEndDate () {
      this.menu.endDate = false
      this.form.createEvent.endDateFormatted = new Date(this.form.createEvent.endDate).toLocaleDateString()
    },
    saveStartTime (time, index) {
      this.$refs.timeStartMenu[index].save(time)
    },
    saveEndTime (time, index) {
      this.$refs.timeEndMenu[index].save(time)
    },
    sortSubLevels () {
      this.form.createEvent.subLevel.sort()
    }
  }
}
</script>

<style scoped>

</style>
